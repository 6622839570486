import {
	Box,
	Divider,
	Typography,
	Grid,
	Paper,
	Chip,
	alpha,
	CircularProgress,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment, useMemo } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import dayjs from 'dayjs';
import { TagCloud } from 'react-tagcloud';
import * as R from 'ramda';

const stopwords = [
	'i',
	'ii',
	'iii',
	'me',
	'my',
	'myself',
	'we',
	'our',
	'ours',
	'ourselves',
	'you',
	'your',
	'yours',
	'yourself',
	'yourselves',
	'he',
	'him',
	'his',
	'himself',
	'she',
	'her',
	'hers',
	'herself',
	'it',
	'its',
	'itself',
	'they',
	'them',
	'their',
	'theirs',
	'themselves',
	'what',
	'which',
	'who',
	'whom',
	'this',
	'that',
	'these',
	'those',
	'am',
	'is',
	'are',
	'was',
	'were',
	'be',
	'been',
	'being',
	'have',
	'has',
	'had',
	'having',
	'do',
	'does',
	'did',
	'doing',
	'a',
	'an',
	'the',
	'and',
	'but',
	'if',
	'or',
	'because',
	'as',
	'until',
	'while',
	'of',
	'at',
	'by',
	'for',
	'with',
	'about',
	'against',
	'between',
	'into',
	'through',
	'during',
	'before',
	'after',
	'above',
	'below',
	'to',
	'from',
	'up',
	'down',
	'in',
	'out',
	'on',
	'off',
	'over',
	'under',
	'again',
	'further',
	'then',
	'once',
	'here',
	'there',
	'when',
	'where',
	'why',
	'how',
	'all',
	'any',
	'both',
	'each',
	'few',
	'more',
	'most',
	'other',
	'some',
	'such',
	'no',
	'nor',
	'not',
	'only',
	'own',
	'same',
	'so',
	'than',
	'too',
	'very',
	's',
	't',
	'can',
	'will',
	'just',
	'don',
	'should',
	'now',
];

const mainClass = {
	numberCard: {
		p: 2,
		minHeight: '150px',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
		justifyContent: 'center',
	},
};

function JobDashboardComponent({
	user = {},
	certifications = [],
	trackingTotalData = {},
	loadingTrackingTotal = false,
	trackingJobStatus = [],
	trackingSavedTitles = [],
	userPrompts = [],
}) {
	const stackData = useMemo(() => {
		return trackingJobStatus.map((item) => {
			return {
				time: dayjs(item.time).local().format('YYYY-MM-DD'),
				Saved: item.APPLY,
				Applied: item.REQUEST,
				Interview: item.INTERVIEW,
				Offer: item.OFFER,
				Archived: item.ARCHIVE,
			};
		});
	}, [trackingJobStatus]);

	const sigmoid = (x) => {
		return Math.exp(x) / (Math.exp(x) + 1);
	};

	const titleData = useMemo(() => {
		let wordList = [];
		trackingSavedTitles.forEach((item) => {
			for (let i = 0; i < item.cnt; i++) {
				wordList = wordList.concat(item.title.match(/[A-Za-z0-9]+/g));
			}
		});
		const wordCountObj = R.countBy(R.toLower)(wordList);
		const wordCountList = [];
		for (const [key, value] of Object.entries(wordCountObj)) {
			if (!stopwords.includes(key)) {
				wordCountList.push({
					value: key,
					count: sigmoid(value),
				});
			}
			if (wordCountList.length === 50) break;
		}
		return wordCountList;
	}, [trackingSavedTitles]);

	const colorOptions = {
		luminosity: 'light',
		hue: 'blue',
	};

	const jobTitleChip = (tag, size, color) => {
		return (
			<Chip
				label={tag.value}
				key={tag.value}
				sx={{
					fontSize: `${size}px`,
					height: `${size + 10}px`,
					borderRadius: `${size + 5}px`,
					m: 0.3,
					backgroundColor: alpha(color, 0.7),
				}}
			/>
		);
	};

	return (
		<>
			<Box
				component="div"
				sx={{
					wordWrap: 'break-word',
					// boxShadow: 2,
					border: 1,
					borderColor: '#f0f0f0',
					p: 2,
				}}
			>
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							My Ideal Job
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div className="grid grid-cols-3 w-full gap-x-4">
							<Typography variant="subtitle1" gutterBottom>
								Location :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.location?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Types :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.jobType?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Level :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.jobLevel?.join(', ')}
							</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Work Type :
							</Typography>
							<Typography
								variant="subtitle1"
								gutterBottom
								className="col-span-2"
							>
								{user?.workType?.join(', ')}
							</Typography>
						</div>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Languages I Speak:
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<Typography variant="subtitle1" gutterBottom className="col-span-2">
							{user?.languages?.join(', ') || '-'}
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Prompts
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{userPrompts?.map((item, index) => (
							<Fragment key={index}>
								<Typography
									variant="subtitle2"
									gutterBottom
									sx={{ fontWeight: 'bold' }}
								>
									{item?.prompt?.promptName}
								</Typography>
								<Typography
									variant="subtitle2"
									gutterBottom
									className="col-span-2"
								>
									{item?.answer}
								</Typography>
								<Divider sx={{ marginBottom: '10px' }} />
							</Fragment>
						))}
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Certifications
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{certifications?.map((item, index) => (
							<Fragment key={index}>
								<Typography
									variant="h6"
									gutterBottom
									className={
										item?.certificationUrl
											? 'col-span-2 cursor-pointer hover:text-[#0f6795]'
											: 'col-span-2'
									}
									onClick={() => {
										if (item?.certificationUrl) {
											window.open(item?.certificationUrl, '_blank');
										}
									}}
								>
									{item?.certificationName}
								</Typography>
								<Typography
									variant="subtitle2"
									gutterBottom
									className="col-span-2"
								>
									{item?.provider} - {item?.issueYear}/{item?.issueMonth}
								</Typography>
							</Fragment>
						))}
					</AccordionDetails>
				</Accordion>
				<Divider />
				{/* statistics */}
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Statistics
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="secondary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.USAGE || 0
										)}
									</Typography>
									<Typography variant="caption">Tailored Resume</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="secondary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.COVER_LETTER_USAGE || 0
										)}
									</Typography>
									<Typography variant="caption">
										Tailored Cover Letter
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="primary"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.APPLY || 0
										)}
									</Typography>
									<Typography variant="caption">Saved Jobs</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#009688"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.REQUEST || 0
										)}
									</Typography>
									<Typography variant="caption">Applied Positions</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#81c784"
										sx={{ fontWeight: 700 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.INTERVIEW || 0
										)}
									</Typography>
									<Typography variant="caption">Interviewing</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6}>
								<Paper sx={mainClass.numberCard}>
									<Typography
										variant="h4"
										color="#ff8a65"
										sx={{ fontWeight: 900 }}
									>
										{loadingTrackingTotal ? (
											<CircularProgress />
										) : (
											trackingTotalData?.OFFER || 0
										)}
									</Typography>
									<Typography variant="caption">Offer Received</Typography>
								</Paper>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Paper
								sx={{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									mt: 2,
								}}
							>
								<Typography variant="h6" gutterBottom>
									Activities over the last 30 days
								</Typography>
								<ResponsiveContainer height={300}>
									<BarChart
										data={stackData}
										margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
									>
										<XAxis dataKey="time" tick={{ fontSize: 12 }} />
										<YAxis tick={{ fontSize: 12 }} />
										<Tooltip wrapperStyle={{ fontSize: '12px' }} />
										<Legend wrapperStyle={{ fontSize: '12px' }} />
										<Bar dataKey="Saved" stackId="a" fill="#64b5f6" />
										<Bar dataKey="Applied" stackId="a" fill="#009688" />
										<Bar dataKey="Interview" stackId="a" fill="#81c784" />
										<Bar dataKey="Offer" stackId="a" fill="#ff8a65" />
										<Bar dataKey="Archived" stackId="a" fill="#90a4ae" />
									</BarChart>
								</ResponsiveContainer>
							</Paper>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Divider />
				<Accordion
					defaultExpanded
					sx={{
						backgroundColor: 'transparent',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="ideal"
						id="ideal"
						sx={{
							backgroundColor: 'transparent',
						}}
					>
						<div
							style={{
								fontSize: '1.2rem',
								fontWeight: '700',
								color: '#334155',
							}}
						>
							Saved Job Titles
						</div>
					</AccordionSummary>
					<AccordionDetails>
						{!!trackingSavedTitles?.length && (
							<TagCloud
								minSize={12}
								maxSize={36}
								tags={titleData}
								className="mt-2"
								colorOptions={colorOptions}
								renderer={jobTitleChip}
							/>
						)}
					</AccordionDetails>
				</Accordion>
			</Box>
		</>
	);
}

export default JobDashboardComponent;
